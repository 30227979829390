import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Truncate from '@/storychief/components/Truncate';

const colorCodingVariants = [
  'color-coding--none',
  'color-coding--1',
  'color-coding--2',
  'color-coding--3',
  'color-coding--4',
  'color-coding--5',
  'color-coding--6',
  'color-coding--7',
  'color-coding--8',
  'color-coding--9',
  'color-coding--10',
  'color-coding--11',
  'color-coding--12',
  'color-coding--13',
  'color-coding--14',
  'color-coding--15',
  'color-coding--16',
  'color-coding--17',
  'color-coding--18',
  'color-coding--19',
  'color-coding--20',
  'color-coding--21',
  'color-coding--22',
  'color-coding--23',
  'color-coding--24',
];

const statusVariants = [
  'published',
  'draft',
  'pending',
  'upcoming',
  'running',
  'completed',
  'approved',
  'scheduled',
  'unpublished',
  'declined',
];

const scoreVariants = ['high', 'medium', 'low', 'hard', 'easy'];

const propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf([
    'default',
    'info',
    'primary',
    'custom-field',
    'success',
    'outline',
    'warning',
    'danger',
    ...statusVariants,
    ...colorCodingVariants,
    ...scoreVariants,
  ]),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  href: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
const defaultProps = {
  children: null,
  variant: 'default',
  size: 'md',
  href: null,
  className: null,
  onClick: null,
};

const BOOTSTRAP_PROPS = ['bsClass', 'bsSize', 'bsStyle', 'bsRole'];

const Label = forwardRef(({ children, variant, size, href, className, onClick, ...props }, ref) => {
  const labelClassName = classNames(className, `label label-${variant} label-${size}`);

  function renderChildren() {
    return <Truncate lines={1}>{children}</Truncate>;
  }

  function getElementProps() {
    const elementProps = {};

    Object.entries(props).forEach(([key, value]) => {
      if (!BOOTSTRAP_PROPS.includes(key)) {
        elementProps[key] = value;
      }
    });

    return elementProps;
  }

  // Render
  if (!children) {
    return null;
  }

  if (href) {
    return (
      <a href={href} className={labelClassName} {...getElementProps()}>
        {renderChildren()}
      </a>
    );
  }

  if (onClick) {
    return (
      <button
        ref={ref}
        type="button"
        onClick={onClick}
        className={labelClassName}
        {...getElementProps()}
      >
        {renderChildren()}
      </button>
    );
  }

  return (
    <div className={labelClassName} {...getElementProps()}>
      {renderChildren()}
    </div>
  );
});

Label.propTypes = propTypes;
Label.defaultProps = defaultProps;
Label.displayName = 'Label';

export default Label;
